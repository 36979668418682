import React from 'react';
import newRelicWrapper from '../logic/new-relic-wrapper';

class DefaultErrorComponent extends React.Component {
  constructor(props) {
    super(props);

    this.newRelicWrapper = new newRelicWrapper();
  }

  componentDidMount() {
    this.newRelicWrapper.noticeError(new Error('Default Error Page'), {
      rid: this.props.uuid,
    });
  }

  render() {
    return (
      <>
        <h1>This page is unavailable</h1>
        <h4>:(</h4>
        <h6>RID: {this.props.uuid}</h6>
      </>
    );
  }
}

export default DefaultErrorComponent;
