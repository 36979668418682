function newRelicWrapper() {
  var isInitialized = false;

  var newrelicInterface = false;

  var _init = () => {
    if (isInitialized) {
      return;
    }

    require('../native/new-relic.native');
    try{
      // eslint-disable-next-line no-undef
      newrelicInterface = newrelic;
    } catch(err) {}

    isInitialized = true;
  };

  this.noticeError = (errorObject, customAttributes) => {
    _init();
    if (!newrelicInterface) {
      return;
    }

    newrelicInterface.noticeError(errorObject, customAttributes);
  };

  this.setCustomAttribute = (name, value) => {
    _init();
    if (!newrelicInterface) {
      return;
    }

    newrelicInterface.setCustomAttribute(name, value);
  };
}

export default newRelicWrapper;
