import './App.css';
import DefaultErrorComponent from './components/DefaultErrorComponent';
import { v4 as uuidv4 } from 'uuid';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <DefaultErrorComponent uuid={uuidv4()} />
      </header>
    </div>
  );
}

export default App;
